import './App.scss';
import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import {
    getBrowserLanguage,
    getCookie,
    isset,
    issetDot,
    loadTranslationsKeys,
    redirect,
    setCookie,
    t,
} from '../Utils';
import Config from '../config/Config';
import Session from '../Session';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import NotificationArea from '../../common/components/notificationArea/NotificationArea';
import i18n from '../../i18n';
import history from '../../history';
import { GlobalTooltip } from '../../common/components/toolTip/Tooltip';
import Banner from '../../common/components/banner/Banner';
import Export from '../../common/containers/export/Export';
import Icon from '../../common/components/icon/Icon';
import Button from '../../common/components/button/Button';
import Text from '../../common/components/text/Text';
import External from '../../common/containers/external/External';
import TourController from '../../common/tours/TourController';
import {
    Checkbox,
    Fab,
    FormControlLabel,
    FormGroup,
    LinearProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import swkLogoMobile from '../../resources/images/swk-logo-mobile.png';
import { posthog } from 'posthog-js';
import RegisterRequest from '../../public/register/RegisterRequest';
import posthogActionProperties from '../../posthogActionProperties';
import Request from '../../common/classes/Request';
const Pagination = React.lazy(() => import('./../Pagination'));
const TopBar = React.lazy(() =>
    import('../../common/components/topBar/TopBar')
);
const BigScreenPage = React.lazy(() =>
    import('../../common/containers/bigScreenPage/bigScreenPage')
);
const FullPage = React.lazy(() =>
    import('../../common/components/fullPage/FullPage')
);
const PagePriority = React.lazy(() =>
    import('../../common/components/fullPage/FullPage').then((module) => ({
        default: module.PagePriority,
    }))
);
const PageContent = React.lazy(() =>
    import('../../common/components/fullPage/FullPage').then((module) => ({
        default: module.PageContent,
    }))
);

// Public
const Register = React.lazy(() => import('../../public/register/Register'));
const Login = React.lazy(() => import('../../public/login/Login'));

// Sentry router
const SentryRoute = Sentry.withSentryRouting(Route);
class App extends React.Component {
    confirmEmailCode = '';
    teamInviteCode = '';

    constructor(props) {
        super(props);
        this.state = {
            session: Session.getParams(),
            loaded: false,
            hideBanner: true,
            toursArray: [
                {
                    title: 'Create Swonkie Account',
                    status: 'checked',
                },
                {
                    posthogId: 'checklistsGettingStartedConnectProfile',
                    title: 'Connect a profile',
                    status: 'active',
                    tourId: 'ConnectProfile',
                },
                {
                    posthogId: 'checklistsGettingStartedCreateFirstPost',
                    title: 'Create your first post',
                    status: 'disabled',
                    tourId: 'CreateFirstPost',
                },
                {
                    posthogId: 'checklistsGettingStartedSchedulePost',
                    title: 'Schedule content',
                    status: 'disabled',
                    tourId: 'SchedulePost',
                },
                {
                    posthogId: 'checklistsGettingStartedCreateFirstReport',
                    title: 'Create a report',
                    status: 'disabled',
                    tourId: 'CreateFirstReport',
                },
            ],
            showCheckList: true,
            hideCompletedChecklist: false,
            phoneWarning: false,
            dontRemember: false,
        };

        const search = window.location.search.substring(1);
        const params = new URLSearchParams(search);

        for (var pair of params.entries()) {
            if (pair[0] === 'confirmEmailCode') {
                this.confirmEmailCode = pair[1];
                break;
            }
            if (pair[0] === 'teamInviteCode') {
                this.teamInviteCode = pair[1];
                break;
            }
        }

        if (this.confirmEmailCode !== '') {
            Request.send(
                {
                    url: Config.backendHost + 'login/confirm',
                    method: 'post',
                    params: { confirmEmailCode: this.confirmEmailCode },
                },
                (data) => {
                    if (data.status == 'ok') {
                        if (isset(data.emailConfirmed, false)) {
                            window.location = '/login?confirmedEmail';
                        } else {
                            window.location = '/';
                        }
                    } else {
                        window.location = '/';
                    }
                }
            );
        } else {
            Session.start();
        }

        Session.setCallback(() => {
            const params = Session.getParams();

            let notWhitelisted = true;

            for (let key in Config.outIframeWhitelist) {
                if (Config.outIframeWhitelist[key] && window.location.href.indexOf(Config.outIframeWhitelist[key]) > 0) {
                    notWhitelisted = false;
                }
            }

            const isInIframe = isset(params.isInsideIframe, false);
            const willRedirectFenix =
                params.loggedIn &&
                !isset(params.checkpoint, false) &&
                !isInIframe &&
                notWhitelisted;

            this.setState({ session: params, loaded: !willRedirectFenix });

            if (params.loggedIn && !isset(params.checkpoint, false)) {
                if (!isInIframe && notWhitelisted) {
                    redirect(Config.fenixAppHost);
                }

                var newsPopupCookie = 'popupNewsTiktokDirectPublishing';

                var maxDate = new Date(2023, 10, 30);
                var today = new Date();
                var newsPopup = getCookie(newsPopupCookie);

                var differenceTime = maxDate.getTime() - today.getTime();
                this.differenceDays = differenceTime / (1000 * 3600 * 24);

                if (this.differenceDays >= 0) {
                    if (!newsPopup) {
                        setCookie(newsPopupCookie, 'open', this.differenceDays);
                        this.openPopup();
                    }
                    this.state.hideBanner = false;
                }
            }
        });

        loadTranslationsKeys();
        this.saveUtmCookie();
        window.addEventListener('message', this.receiveMessage, false);
    }

    saveUtmCookie() {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        var utms = vars
            .filter(function (v) {
                return v !== '';
            })
            .map(function (item) {
                return item.split('=');
            })
            .filter((v) => {
                return typeof v[0] == 'string' && v[0].startsWith('utm');
            })
            .reduce(function (utms, item) {
                utms[item[0]] = item[1];
                return utms;
            }, {});
        if (Object.keys(utms).length > 0) {
            setCookie('utms', JSON.stringify(utms), null, Config.cookieHost);
        }
    }

    lastToursArray = null;
    componentDidMount() {
        posthogActionProperties();
        var { toursArray } = this.state;
        this.lastToursArray = toursArray;

        if (
            posthog.isFeatureEnabled(
                'checklistsGettingStartedCreateFirstReportShow'
            )
        ) {
            var firstReportKey = toursArray.findIndex(
                (tempTour) => tempTour.tourId === 'CreateFirstReport'
            );
            delete toursArray[firstReportKey];
        }

        posthog.onFeatureFlags(() => {
            var { toursArray, showCheckList } = this.state;
            toursArray = toursArray.map((tour, key) => {
                var profileKey = toursArray.findIndex(
                    (tempTour) => tempTour.tourId === 'ConnectProfile'
                );
                if (
                    key > profileKey &&
                    toursArray[profileKey].status === 'checked'
                ) {
                    tour = { ...tour, status: 'active' };
                }

                if (posthog.isFeatureEnabled(tour.posthogId)) {
                    tour = { ...tour, status: 'checked' };
                    if (tour.tourId === 'ConnectProfile') {
                        toursArray[profileKey].status = 'checked';
                    }
                }
                return tour;
            });

            if (
                !showCheckList &&
                JSON.stringify(this.lastToursArray) !==
                JSON.stringify(toursArray)
            ) {
                showCheckList = true;
            }

            this.setState({ toursArray, showCheckList: showCheckList });
            this.lastToursArray = toursArray;
        });

        var toursArrayTemp = toursArray.map((tour) => {
            if (posthog.isFeatureEnabled(tour.posthogId)) {
                tour = { ...tour, status: 'checked' };
            }
            return tour;
        });
        var ToursUnchecked = toursArrayTemp.filter(
            (tour) => tour.status !== 'checked'
        ).length;

        if (this.teamInviteCode) {
            Request.send(
                {
                    url: Config.backendHost + 'register/teamInvite',
                    method: 'post',
                    params: { teamInviteCode: this.teamInviteCode },
                },
                (data) => {
                    if (
                        data.status == 'ok' &&
                        issetDot(data, 'data.email', null)
                    ) {
                        setTimeout(() => {
                            var emailInput = document.querySelector(
                                'input[type="email"]'
                            );
                            if (emailInput) {
                                emailInput.setAttribute(
                                    'value',
                                    data.data.email
                                );
                                emailInput.dispatchEvent(
                                    new Event('input', { bubbles: true })
                                );
                            }
                        }, 500);
                    }
                }
            );
        }

        this.setState({
            toursArray,
            hideCompletedChecklist: ToursUnchecked < 1,
        });
    }

    openPopup() {
        TourController.load('PopupNewsTiktokDirectPublishing');
    }

    receiveMessage(event) {
        if (typeof event.data !== 'undefined') {
            if (typeof event.data.type !== 'undefined') {
                switch (event.data.type) {
                case 'reload':
                    window.location.reload();
                    break;
                }
            }
            if (typeof event.data.path !== 'undefined') {
                window.location = event.data.path;
                return;
            }
        }
    }

    render() {
        const isLoaded = this.state.loaded || false;
        const toursArray = this.state.toursArray;
        var ToursUnchecked = toursArray.filter(
            (tour) => tour.status !== 'checked'
        ).length;
        var toursChecked = toursArray.filter(
            (tour) => tour.status === 'checked'
        ).length;
        var progress = (toursChecked * 100) / toursArray.length;
        var closeForever = true;
        if (!isLoaded) return null;

        const isLoggedIn = this.state.session.loggedIn;

        let hideTopBar = this.state.session.hideTopBar || false;

        i18n.changeLanguage(
            isset(this.state.session.language, getBrowserLanguage())
        );
        var hideBanner = this.state.hideBanner;
        return (
            <Suspense fallback={null}>
                <Router history={history}>
                    <SentryRoute
                        path="/export"
                        exact
                        render={(props) => <Export {...props} />}
                    />
                    <SentryRoute
                        path="/externalShare"
                        exact
                        render={(props) => <External {...props} />}
                    />
                    {['/export', '/externalShare'].findIndex(
                        (path) => path === window.location.pathname
                    ) >= 0 ? null : !isLoggedIn ? (
                            <GoogleReCaptchaProvider
                                reCaptchaKey={Config.captchaV3Key}
                            >
                                <Switch>
                                    <SentryRoute path="/login" component={Login} />
                                    <SentryRoute
                                        path="/register"
                                        component={Register}
                                    />
                                    {window.location.pathname !== '/export' && (
                                        <Redirect to="/login" />
                                    )}
                                </Switch>
                            </GoogleReCaptchaProvider>
                        ) : (
                            <Switch>
                                <SentryRoute
                                    path="/listening/projects/analytics/bigScreen"
                                    exact
                                    render={(props) => <BigScreenPage {...props} />}
                                />
                                {
                                    <SentryRoute
                                        render={() => (
                                            <FullPage>
                                                {isset(
                                                    this.state.session.checkpoint,
                                                    null
                                                ) === null &&
                                                this.state.phoneWarning && (
                                                    <div className="swonkieMobileArea">
                                                        <div className="swonkieMobileContentArea">
                                                            <div className="swonkieMobileTopArea">
                                                                <img
                                                                    src={
                                                                        swkLogoMobile
                                                                    }
                                                                    style={{
                                                                        width: '64px',
                                                                        height: '64px',
                                                                    }}
                                                                />
                                                                <Text size="Huge">
                                                                    {i18n.t(
                                                                        'Experience Swonkie on desktop'
                                                                    )}
                                                                </Text>
                                                                <Text>
                                                                    {i18n.t(
                                                                        'Swonkie is best enjoyed on a desktop. With a larger screen and enhanced features, you can get the most out of what we have to offer. Consider switching to our desktop version for a seamless experience.'
                                                                    )}
                                                                </Text>
                                                            </div>
                                                            <div className="swonkieMobileBottomArea">
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                onClick={() =>
                                                                                    this.setState(
                                                                                        {
                                                                                            dontRemember:
                                                                                                !this
                                                                                                    .state
                                                                                                    .dontRemember,
                                                                                        }
                                                                                    )
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            <Text
                                                                                size="Small"
                                                                                color="grey"
                                                                            >
                                                                                {i18n.t(
                                                                                    'Don’t remind me again'
                                                                                )}
                                                                            </Text>
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                                <Button
                                                                    type="Tertiary"
                                                                    onClick={() =>
                                                                        this.setState(
                                                                            {
                                                                                phoneWarning: false,
                                                                            },
                                                                            () => {
                                                                                if (
                                                                                    this
                                                                                        .state
                                                                                        .dontRemember
                                                                                )
                                                                                    setCookie(
                                                                                        'phoneWarning',
                                                                                        true
                                                                                    );
                                                                            }
                                                                        )
                                                                    }
                                                                    text={i18n.t(
                                                                        'Continue on Mobile'
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <PageContent>
                                                    {!hideTopBar ? (
                                                        <>
                                                            <TopBar />
                                                            {!this.state.session
                                                                .emailConfirmed ? (
                                                                    <Banner
                                                                        data-id="resend-email-banner"
                                                                        background="darkorange"
                                                                        style={{
                                                                            justifyContent:
                                                                        'center',
                                                                            gap: '16px',
                                                                        }}
                                                                        message={t(
                                                                            <>
                                                                                <b>
                                                                            Verify
                                                                            your
                                                                            email.
                                                                                </b>{' '}
                                                                        A
                                                                        confirmation
                                                                        has been
                                                                        sent to
                                                                        your
                                                                        inbox;
                                                                        check
                                                                        your spam if you dont see it.
                                                                            </>
                                                                        )}
                                                                        color="darkorange"
                                                                        disableButton={
                                                                            this.state
                                                                                .disableBannerButton
                                                                        }
                                                                        onClick={() =>
                                                                            this.setState(
                                                                                {
                                                                                    disableBannerButton: true,
                                                                                },
                                                                                () => {
                                                                            // eslint-disable-line
                                                                                    RegisterRequest.postRegisterResend(
                                                                                        () => {
                                                                                            setTimeout(
                                                                                                () => {
                                                                                                    this.setState(
                                                                                                        {
                                                                                                            disableBannerButton: false,
                                                                                                        }
                                                                                                    );
                                                                                                },
                                                                                                20000
                                                                                            );
                                                                                        }
                                                                                    );
                                                                                }
                                                                            )
                                                                        }
                                                                        buttonType="verifyEmail"
                                                                        buttonText={
                                                                            this.state
                                                                                .disableBannerButton ? (
                                                                                    <>
                                                                                        {i18n.t(
                                                                                            'Sent'
                                                                                        )}{' '}
                                                                                        <Icon name="check" />
                                                                                    </>
                                                                                ) : (
                                                                                    i18n.t(
                                                                                        'Re-send verification email'
                                                                                    )
                                                                                )
                                                                        }
                                                                    />
                                                                ) : (
                                                                    !hideBanner && (
                                                                        <Banner
                                                                            message={i18n.t(
                                                                                'We have news on Swonkie. Consult everything here'
                                                                            )}
                                                                            color="Black"
                                                                            onClick={() =>
                                                                                this.openPopup()
                                                                            }
                                                                            buttonText={i18n.t(
                                                                                'Check News'
                                                                            )}
                                                                        />
                                                                    )
                                                                )}
                                                        </>
                                                    ) : null}
                                                </PageContent>
                                                <PagePriority
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <NotificationArea />
                                                    <Pagination />
                                                </PagePriority>
                                                {isset(
                                                    this.state.session.checkpoint,
                                                    null
                                                ) === null &&
                                                !closeForever && (
                                                    <div
                                                        style={{
                                                            position: 'fixed',
                                                            left: '24px',
                                                            bottom: '24px',
                                                        }}
                                                    >
                                                        {this.state
                                                            .showCheckList && (
                                                            <div className="TourChecklistsArea">
                                                                <div className="TourChecklistsTopArea">
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                    'flex',
                                                                            justifyContent:
                                                                                    'space-between',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            size="Huge"
                                                                            color="darkPink"
                                                                        >
                                                                            {ToursUnchecked <
                                                                                    1
                                                                                ? i18n.t(
                                                                                    'You\'re all set! 🎉'
                                                                                )
                                                                                : i18n.t(
                                                                                    'Getting Started'
                                                                                )}
                                                                        </Text>
                                                                        {ToursUnchecked >=
                                                                                1 && (
                                                                            <Icon
                                                                                onClick={() =>
                                                                                    this.setState(
                                                                                        {
                                                                                            showCheckList: false,
                                                                                        }
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    transform:
                                                                                                'rotate(90deg)',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                name="Chevron Right"
                                                                                color="darkPink"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <Text>
                                                                        {ToursUnchecked <
                                                                                1
                                                                            ? i18n.t(
                                                                                'Now that you understand how Swonkie can assist you in managing your social media, you\'re ready to go.'
                                                                            )
                                                                            : i18n.t(
                                                                                'Maximize your Swonkie experience by completing this setup checklist.'
                                                                            )}
                                                                    </Text>
                                                                    <div className="TourChecklistsProgressArea">
                                                                        <Text color="darkPink">
                                                                            {
                                                                                progress
                                                                            }
                                                                                %
                                                                        </Text>
                                                                        <LinearProgress
                                                                            className="TourChecklistsProgress"
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                            variant="determinate"
                                                                            value={
                                                                                progress
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <FormGroup className="TourChecklistsMiddleArea">
                                                                    {toursArray.map(
                                                                        (
                                                                            tour,
                                                                            key
                                                                        ) => {
                                                                            return (
                                                                                <FormControlLabel
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    className="TourChecklistsItem"
                                                                                    disabled={
                                                                                        tour.status ===
                                                                                            'disabled'
                                                                                    }
                                                                                    control={
                                                                                        <Checkbox
                                                                                            onClick={() => {
                                                                                                TourController.load(
                                                                                                    tour.tourId
                                                                                                );
                                                                                                this.setState(
                                                                                                    {
                                                                                                        showCheckList: false,
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                            disabled={
                                                                                                tour.status ===
                                                                                                    'disabled'
                                                                                            }
                                                                                            size="small"
                                                                                            checked={
                                                                                                tour.status ===
                                                                                                    'checked'
                                                                                            }
                                                                                            style={{
                                                                                                color:
                                                                                                        tour.status ===
                                                                                                            'checked'
                                                                                                            ? Config
                                                                                                                .colors
                                                                                                                .darkpink
                                                                                                            : Config
                                                                                                                .colors
                                                                                                                .black,
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    label={
                                                                                        <div
                                                                                            style={{
                                                                                                alignItems:
                                                                                                        'center',
                                                                                                display:
                                                                                                        'flex',
                                                                                                width: '100%',
                                                                                                justifyContent:
                                                                                                        'space-between',
                                                                                            }}
                                                                                        >
                                                                                            <Text
                                                                                                color={
                                                                                                    tour.status ===
                                                                                                            'checked'
                                                                                                        ? 'darkpink'
                                                                                                        : 'black'
                                                                                                }
                                                                                            >
                                                                                                {i18n.t(
                                                                                                    tour.title
                                                                                                )}
                                                                                            </Text>
                                                                                            {tour.status !==
                                                                                                    'checked' && (
                                                                                                <Icon name="Chevron Right" />
                                                                                            )}
                                                                                        </div>
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                                </FormGroup>
                                                                <div className="TourChecklistsBottomArea">
                                                                    <Text
                                                                        color="grey"
                                                                        size="Small"
                                                                    >
                                                                        {t(
                                                                            <>
                                                                                    Need
                                                                                    help?{' '}
                                                                                <span
                                                                                    onClick={() =>
                                                                                        window.toggleChat()
                                                                                    }
                                                                                    style={{
                                                                                        textDecorationLine:
                                                                                                'underline',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                        Talk
                                                                                        to
                                                                                        our
                                                                                        support
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div
                                                            style={{
                                                                width: 'fit-content',
                                                            }}
                                                            tooltip-position="left"
                                                            tooltip={
                                                                ToursUnchecked <
                                                                    1
                                                                    ? i18n.t(
                                                                        'Close'
                                                                    )
                                                                    : i18n.t(
                                                                        'Getting Started'
                                                                    )
                                                            }
                                                        >
                                                            <Fab
                                                                onClick={() =>
                                                                    this.setState(
                                                                        {
                                                                            showCheckList:
                                                                                !this
                                                                                    .state
                                                                                    .showCheckList,
                                                                        }
                                                                    )
                                                                }
                                                                className="TourChecklistsFAB"
                                                                color="primary"
                                                                aria-label="add"
                                                            >
                                                                {ToursUnchecked <
                                                                    1 ? (
                                                                        <CloseIcon
                                                                            style={{
                                                                                color: Config
                                                                                    .colors
                                                                                    .darkpink,
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <FormatListBulletedRoundedIcon
                                                                            style={{
                                                                                color: Config
                                                                                    .colors
                                                                                    .darkpink,
                                                                            }}
                                                                        />
                                                                    )}
                                                                <span className="TourChecklistsFABUncheckedNumber">
                                                                    {
                                                                        ToursUnchecked
                                                                    }
                                                                </span>
                                                                handleLogoutTeam{' '}
                                                            </Fab>
                                                        </div>
                                                    </div>
                                                )}
                                            </FullPage>
                                        )}
                                    />
                                }
                            </Switch>
                        )}
                </Router>
                <GlobalTooltip />
            </Suspense>
        );
    }
}
export default App;
